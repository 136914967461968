import React from "react";

// interfaces
import { IconComponentProps } from "@/types/components/IconComponentProps.interface";

function ListGearIcon(props: IconComponentProps<HTMLSpanElement>) {
  return (
    <span {...props}>
      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 25.3346H4" stroke="#374151" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12 15.9987H4" stroke="#374151" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M28 6.66667H4" stroke="#374151" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M25.4949 17.1716C27.057 18.7337 27.057 21.2663 25.4949 22.8284C23.9328 24.3905 21.4002 24.3905 19.8381 22.8284C18.276 21.2663 18.276 18.7337 19.8381 17.1716C21.4002 15.6095 23.9328 15.6095 25.4949 17.1716" stroke="#374151" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M22.6667 16.0013V14.668" stroke="#374151" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M22.6667 24V25.3333" stroke="#374151" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M26.6665 19.9987H27.9998" stroke="#374151" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M18.6668 19.9987H17.3335" stroke="#374151" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M25.4931 17.1752L26.4398 16.2285" stroke="#374151" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M19.8402 22.8261L18.8935 23.7728" stroke="#374151" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M25.4931 22.8261L26.4398 23.7728" stroke="#374151" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M19.8402 17.1752L18.8935 16.2285" stroke="#374151" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    </span>
  );
}

export default ListGearIcon;
