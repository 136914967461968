import React from "react";

//interfaces
import { IconComponentProps } from "@/types/components/IconComponentProps.interface";

function LoliveLogoBlackIcon(props: IconComponentProps<HTMLSpanElement>) {
  return (
    <span {...props}>
      <svg
        width="102"
        height="36"
        viewBox="0 0 102 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M40.9287 15.3249C40.9287 13.8235 42.2004 12.5982 43.7193 12.6154C45.2559 12.6154 46.4922 13.8407 46.4922 15.3422C46.4922 16.8263 45.2206 18.0517 43.7016 18.0517C42.1827 18.0517 40.9287 16.8091 40.9464 15.3076L40.9287 15.3249Z"
          fill="#EC1F25"
        />
        <path
          d="M40.9287 27.0081C40.9464 25.4894 42.2004 24.2986 43.7546 24.3159C45.2912 24.3331 46.5099 25.5757 46.4922 27.0771C46.4746 28.5613 45.2029 29.7694 43.6663 29.7521C42.1474 29.7348 40.911 28.4923 40.9464 27.0081H40.9287Z"
          fill="#EC1F25"
        />
        <path
          d="M75.1929 24.6616C76.7118 20.6578 78.2308 16.6712 79.732 12.6846C79.8027 12.4948 79.891 12.443 80.0853 12.443C81.4629 12.443 82.8405 12.443 84.2535 12.443C84.1828 12.6328 84.1122 12.8054 84.0415 12.978C81.8338 18.3625 79.6261 23.7642 77.4183 29.1486C77.3477 29.3385 77.2594 29.4075 77.0298 29.4075C75.7404 29.4075 74.4688 29.4075 73.1794 29.4075C73.0205 29.4075 72.9498 29.3557 72.8969 29.2177C70.6361 23.6779 68.3577 18.1208 66.0793 12.5811C66.0793 12.5465 66.0617 12.512 66.044 12.4603C66.1147 12.4085 66.203 12.4257 66.2736 12.4257C67.5806 12.4257 68.9053 12.4257 70.2122 12.4257C70.4065 12.4257 70.4772 12.4948 70.5478 12.6673C72.0491 16.6194 73.5503 20.5715 75.0516 24.5235C75.0516 24.5753 75.0693 24.6098 75.1399 24.6616H75.1929Z"
          fill="#060606"
        />
        <path
          d="M50.1484 15.9807C50.1484 12.9951 50.1484 10.0095 50.1484 7.02388C50.1484 6.81679 50.1838 6.76501 50.4134 6.76501C51.6144 6.76501 52.8331 6.76501 54.0341 6.76501C54.2637 6.76501 54.352 6.79953 54.352 7.0584C54.352 12.719 54.352 18.3796 54.352 24.0402C54.352 24.3336 54.352 24.6269 54.4226 24.9203C54.6346 25.8005 55.2351 26.2492 56.1888 26.3182C56.6304 26.3527 57.0719 26.3182 57.5135 26.1974C57.6371 26.1629 57.6901 26.1974 57.7431 26.3182C58.0787 27.1984 58.3966 28.0785 58.7498 28.9587C58.8028 29.0968 58.7675 29.1485 58.6438 29.2003C57.2662 29.7526 55.8532 30.0114 54.3696 29.7871C52.3562 29.4764 50.8902 28.3029 50.378 26.2665C50.2367 25.7142 50.1838 25.1619 50.1838 24.5924C50.1838 21.7276 50.1838 18.8628 50.1838 15.998L50.1484 15.9807Z"
          fill="#060606"
        />
        <path
          d="M60.6035 20.8988C60.6035 18.1548 60.6035 15.428 60.6035 12.684C60.6035 12.4942 60.6388 12.4252 60.8685 12.4252C62.0871 12.4252 63.3235 12.4252 64.5421 12.4252C64.7717 12.4252 64.8071 12.4942 64.8071 12.684C64.8071 18.1721 64.8071 23.6601 64.8071 29.1481C64.8071 29.3379 64.7717 29.407 64.5598 29.407C63.3235 29.407 62.0871 29.407 60.8685 29.407C60.6565 29.407 60.6212 29.3379 60.6212 29.1481C60.6212 26.4041 60.6212 23.6601 60.6212 20.9161L60.6035 20.8988Z"
          fill="#060606"
        />
        <path
          d="M62.7052 10.6997C61.5925 10.7687 60.2502 9.83679 60.2325 8.33536C60.2325 7.04101 61.3452 5.98828 62.7052 5.98828C64.0652 5.98828 65.1779 7.04101 65.1602 8.35261C65.1602 9.8368 63.8179 10.786 62.7052 10.6997Z"
          fill="#060606"
        />
        <path
          d="M100.326 17.1887C99.6721 15.3249 98.5418 13.8407 96.7226 12.8915C95.0094 12.0114 93.1902 11.8043 91.3004 12.0631C88.7924 12.4083 86.8672 13.6509 85.5426 15.7391C83.9353 18.276 83.7057 21.0373 84.5182 23.8848C85.0657 25.7659 86.1607 27.3019 87.8033 28.4064C89.6578 29.6662 91.7596 30.0459 93.985 29.7698C95.9808 29.5281 97.7646 28.7688 99.3542 27.578C99.4779 27.4917 99.4602 27.4227 99.4249 27.3019C99.1776 26.6461 98.9303 25.9903 98.6831 25.3345C98.6124 25.1446 98.5771 24.8513 98.4358 24.7822C98.2768 24.7132 98.0649 24.9203 97.8706 25.0066C96.2104 25.7832 94.4972 26.2837 92.6427 26.2146C92.0068 26.1801 91.3887 26.042 90.8412 25.7487C89.3222 24.9375 88.7041 23.5914 88.4568 21.9864H88.7747C92.7133 21.9864 96.6519 21.9864 100.608 21.9864C100.661 21.9864 100.714 21.9864 100.75 21.9864C100.873 21.9864 100.908 21.9519 100.908 21.8311C100.961 20.2434 100.855 18.6902 100.326 17.1715V17.1887ZM96.44 19.0181C93.8967 19.0181 91.371 19.0181 88.8277 19.0181C88.6511 19.0181 88.5804 19.0181 88.6334 18.7937C89.0926 17.275 89.9757 16.136 91.6006 15.7218C92.8899 15.3939 94.1439 15.4975 95.239 16.3258C95.8748 16.8091 96.2281 17.4994 96.4753 18.2415C96.546 18.4831 96.599 18.742 96.6696 19.0181H96.4223H96.44Z"
          fill="#060606"
        />
        <path
          d="M35.7102 0C36.063 0.103896 36.4158 0.225108 36.6804 0.502164C36.998 0.796537 37.192 1.16017 37.2273 1.59307C37.2273 1.7316 37.2273 1.87013 37.2273 2.02597C37.2273 12.71 37.2273 23.3939 37.2273 34.0606C37.2273 34.7013 37.0509 35.2554 36.504 35.6537C36.2218 35.8788 35.8866 36 35.5162 36C35.428 36 35.3398 36 35.2516 36C24.3145 36 13.3775 36 2.45808 36C1.69955 36 1.13505 35.7403 0.729325 35.0996C0.623483 34.9091 0.570562 34.7013 0.5 34.5108V1.52381C0.588202 1.24675 0.658763 0.987013 0.835167 0.744589C1.17033 0.34632 1.57606 0.121212 2.06999 0H35.7102Z"
          fill="#EC1F25"
        />
        <path
          d="M14.7707 20.5371C14.8237 18.5351 15.6538 16.2744 17.632 14.514C18.7623 13.5131 20.087 12.8745 21.5883 12.6157C24.0256 12.2015 26.3393 12.4949 28.4234 13.8755C29.9247 14.8592 30.9667 16.2053 31.5496 17.8621C32.468 20.4508 32.362 22.9877 31.108 25.4556C30.3662 26.9225 29.2359 28.0442 27.7699 28.8381C26.6219 29.4594 25.3856 29.77 24.0786 29.8391C22.595 29.9254 21.1467 29.7183 19.7691 29.1315C18.0735 28.3894 16.7842 27.2159 15.9011 25.6109C15.1063 24.213 14.7884 22.6943 14.7707 20.5371ZM23.4604 15.7739C23.3368 15.7739 23.1955 15.7739 23.0719 15.7739C21.2703 15.8947 19.9457 16.7403 19.2216 18.3453C18.4091 20.1574 18.4621 22.0212 19.2216 23.8506C19.5748 24.7135 20.193 25.4038 21.0231 25.9043C21.8179 26.3702 22.6833 26.5255 23.6017 26.5083C25.4739 26.491 27.1341 25.3693 27.7699 23.6435C28.2998 22.2111 28.3528 20.7442 27.9642 19.26C27.4344 17.1718 25.7565 15.7221 23.4428 15.7739H23.4604Z"
          fill="#FFFEFE"
        />
        <path
          d="M6.62793 16.2223C6.62793 13.1677 6.62793 10.113 6.62793 7.05835C6.62793 6.834 6.68092 6.78223 6.91052 6.78223C8.00556 6.78223 9.1006 6.78223 10.1956 6.78223C10.4076 6.78223 10.4782 6.834 10.4782 7.05835C10.4782 12.7707 10.4782 18.4658 10.4782 24.1782C10.4782 24.5579 10.4782 24.9203 10.6019 25.2827C10.8668 26.1283 11.538 26.6116 12.4917 26.6633C12.8979 26.6806 13.2865 26.6633 13.675 26.577C13.834 26.5425 13.9223 26.577 13.993 26.7324C14.3815 27.4399 14.8584 28.113 15.4059 28.6998C15.4942 28.7861 15.5296 28.8206 15.3706 28.9069C14.4345 29.4246 13.4278 29.7007 12.3857 29.8043C10.9728 29.9596 9.63046 29.7525 8.46477 28.8896C7.63466 28.2683 7.14013 27.4399 6.8752 26.4735C6.69858 25.835 6.64559 25.1964 6.64559 24.5406C6.64559 21.7621 6.64559 19.0008 6.64559 16.2223H6.62793Z"
          fill="#FFFEFE"
        />
      </svg>
    </span>
  );
}

export default LoliveLogoBlackIcon;
