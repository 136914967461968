"use client";

import Head from "next/head";
import Image from "next/image";
import { useSearchParams } from "next/navigation";
import classNames from "classnames";

// interfaces
import { ErrorPageContentProps } from "./ErrorPageContent.interface";

// components
import Button from "@/shared/Button";

// helpers
import { ERROR_PAGE_CONTENT, MESSAGE_ERROR, SUCCESS_ERROR } from "./helpers";
import account from "@/api/account";
import useRouter from "@/hooks/useRouter";

// assets
import styles from "@/assets/styles/containers/error-page-content.module.scss";


const ErrorPageContent = ({ path }: ErrorPageContentProps) => {
  
  const { header, description, subDescription, image, meta } = ERROR_PAGE_CONTENT[path] || {};
  
  const isTokenExpiredPage = path === "/401";

  const searchParams = useSearchParams();
  const { push } = useRouter();

  const messageFromRouter = searchParams.get("message");
  const token = searchParams.get("token");
  const uid = searchParams.get("uid");

  const isComingSoonPage = path === "/coming-soon";

  // methods
  const reactivate = () => {
    if (token && uid) {
      account.putRegisterReactivate({ token, uid })
        .then(() => push(`/login?message=${SUCCESS_ERROR}`))
        .catch(() => push(`/login?message=${MESSAGE_ERROR}`));
    }
  };

  const renderButton = () => {
    if (isComingSoonPage) {
      return (
        <Button to="/#brief-us" type="primary">
          Brief Us
        </Button>
      );
    }

    if (isTokenExpiredPage) {
      return (
        <Button onClick={reactivate} type="primary">
          Resend activation link
        </Button>
      );
    }

    return (
      <Button to={process.env.NEXT_PUBLIC_IS_OPS_DASHBOARD ? "/ops/dashboard" : "/"} type="primary">
        Return to the homepage
      </Button>
    );
  };

  return (
    <>
      <Head>
        {meta}
      </Head>
      <div className={classNames(styles["locl-error-page"], { [styles["locl-error-page__coming-soon"]]: isComingSoonPage })}>
        <div className={styles["locl-error-page__description-block"]}>
          { header ? <h1 className={styles["locl-error-page__description-block-header"]}>{ header }</h1> : null }
          { description ? <h2 className={styles["locl-error-page__description-block-description"]}>{ description }</h2> : null }
          { messageFromRouter || subDescription ? <h3 className={styles["locl-error-page__description-block-sub-description"]}>{ messageFromRouter || subDescription }</h3> : null }
          { renderButton() }
        </div>
        <div className={styles["locl-error-page__image-block"]}>
          <Image
            src={image}
            alt={`${description} image`}
            className={styles["locl-error-page__image-block-image"]}
            loading="lazy"
          />
        </div>
      </div>
    </>
  )
}

export default ErrorPageContent;
